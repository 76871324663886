import { create } from 'zustand'
import { SelectOption } from '@apg.gg/core/lib/Select';
import { UserHardware } from '@/domain/account/setup.interface';
import { ApgUser } from '@/domain/account.interface';
import { UserMenu } from '@/domain/account/profile.interface';

export interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  birthDate: string;
  country: string;
  username: string;
  password: string;
}

type Store = {
  isFetching: boolean;
  pendingFetches: number;
  token: string;
  temporaryData: FormData;
  countries: SelectOption[];
  hardwares: UserHardware[];
  userProfile: ApgUser;
  userMenu: UserMenu[];
  error: null;
  errorRetries: {},
  setIsFetching: (isFetching: boolean) => void;
  setPendingFetches: (pendingFetches: number) => void;
  setToken: (token: string) => void;
  setTemporaryData: (temporaryData: FormData) => void;
  setCountries: (countries: SelectOption[]) => void;
  setHardwares: (hardwares: UserHardware[]) => void;
  setError: (error: null) => void;
  setUserProfile: (userProfile: ApgUser) => void;
  setUserMenu: (userMenu: UserMenu[]) => void;
  setErrorRetries: (errorRetries: {}) => void;
  clearData: () => void;
}

export const initialState = {
  isFetching: false,
  pendingFetches: 0,
  token: '',
  temporaryData: {
    firstName: '',
    lastName: '',
    email: '',
    birthDate: '',
    country: '',
    username: '',
    password: '',
  },
  countries: [],
  hardwares: [],
  userProfile: {} as ApgUser,
  userMenu: [],
  errorRetries: {},
  error: null,
};

const useGlobalStore = create<Store>((set) => ({
  ...initialState,
  setIsFetching: (isFetching: boolean) => set((state) => ({ ...state, isFetching })),
  setPendingFetches: (pendingFetches: number) => set((state) => ({ ...state, pendingFetches })),
  setToken: (token: string) => set((state) => ({ ...state, token })),
  setTemporaryData: (temporaryData: FormData) => set((state) => ({ ...state, temporaryData })),
  setCountries: (countries: SelectOption[]) => set((state) => ({ ...state, countries })),
  setHardwares: (hardwares: UserHardware[]) => set((state) => ({ ...state, hardwares })),
  setError: (error: null) => set((state) => ({ ...state, error })),
  setUserProfile: (userProfile: ApgUser) => set((state) => ({ ...state, userProfile })),
  setUserMenu: (userMenu: UserMenu[]) => set((state) => ({ ...state, userMenu })),
  setErrorRetries: (errorRetries: {}) => set((state) => ({ ...state, errorRetries })),
  clearData: () => set(() => initialState),
}));

export default useGlobalStore;